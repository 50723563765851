nav {
    border-bottom: 1px solid #aaa;
}
footer {
    border-top: 1px solid #aaa;
    padding: 10px 0;
    margin:0;
    padding: 0;
}

.body-container {
    display: flex;
    flex-direction: column;
    min-height: 70vh;
}